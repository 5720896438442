import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    DesktopPartners: () =>
      import("./desktop/DesktopPartners/DesktopPartners.vue"),
    MobilePartners: () => import("./mobile/MobilePartners/MobilePartners.vue"),
  },
})
export default class Partners extends Vue {}
